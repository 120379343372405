import React  from "react";
import NewPatientInfo from "../components/NewPatientInfo";

const NewPatient = () => {
    return(
      <div className = "Text__Body">
        <NewPatientInfo/>
      </div>
    )
}

export default NewPatient;