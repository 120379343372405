import React  from "react";
import DentalServices from "../components/DentalServices"

const Services = () => {
    return(
      <div className = "Text__Body">
        <DentalServices/>
      </div>
    )
}

export default Services;